/* eslint no-console:0 */

import "~/stylesheets/application.scss"

import React from "react"
import { createRoot } from "react-dom/client"

import Router from "~/routers"

createRoot(document.getElementById("app")).render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
)
